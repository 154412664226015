import React, { useEffect, useState, useContext } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { Box, Link, FormControlLabel, Typography, Switch, Stack } from '@mui/material';

import AlertContext from '../ui/AlertContext';
import PageCards from '../ui/PageCards';
import LoadingSpinner from '../ui/LoadingSpinner';
import CustomClaimsContext from '../auth/CustomClaimsContext';
import { db } from '../../App';

export default function PublicInfoConsent(props) {
	const auth = getAuth();
	const claimsCtx = useContext(CustomClaimsContext);
	const { claims } = claimsCtx;
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [isUploading, setIsUploading] = useState(false);
	const [data, setData] = useState(null);
	const [publicInfoConsent, setPublicInfoConsent] = useState(null);
	const [publicInfoPositions, setPublicInfoPositions] = useState(null);
	const [link, setLink] = useState('');

	//gets info on member once logged in
	useEffect(() => {
		const getData = async () => {
			if (!publicInfoConsent) {
				const docRef = doc(db, 'Users', claims?.user_id);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const data = docSnap.data();
					setPublicInfoConsent(data?.PublicInfoConsent || {});
					setPublicInfoPositions(data?.Positions || {});
					// console.log('Document data:', docSnap.data());
				} else {
					setPublicInfoConsent({});
					setPublicInfoPositions({});
					// docSnap.data() will be undefined in this case
					// console.log('No such document!');
				}
			}
		};
		if (claims?.user_id) getData();
	}, [props.loadedData, claims?.user_id]);

	// sets data
	useEffect(() => {
		// console.log('loadedData:', props.loadedData);
		setData(props.loadedData?.Data);
	}, [props.loadedData]);

	//setIsLoading
	useEffect(() => {
		if (data && publicInfoConsent && publicInfoPositions && isLoading) setIsLoading(false);
	}, [data, publicInfoConsent, publicInfoPositions, isLoading]);

	const handleConsent = async (toggleName) => {
		setIsUploading(true);

		const newData = { ...publicInfoConsent };
		newData[toggleName] = !newData[toggleName];

		try {
			const ref = doc(db, 'Users', auth.currentUser.uid);
			await updateDoc(ref, { PublicInfoConsent: newData });

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage(error);
			alertCtx.setTimer(10000);
			newData[toggleName] = !newData[toggleName]; //resets consent if it was not successfully saved
			console.log(error);
		}

		setPublicInfoConsent(newData);
	};

	const handlePositions = async (toggleName) => {
		setIsUploading(true);

		const newData = { ...publicInfoPositions };
		newData[toggleName].DisplayPublically = !newData[toggleName].DisplayPublically;

		try {
			const ref = doc(db, 'Users', auth.currentUser.uid);
			await updateDoc(ref, { Positions: newData });

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage(error);
			alertCtx.setTimer(10000);
			newData[toggleName].DisplayPublically = !newData[toggleName].DisplayPublically; //resets consent if it was not successfully saved
			console.log(error);
		}

		setPublicInfoPositions(newData);
	};

	// setLink
	useEffect(() => {
		if (data) setLink(`/people/${data.Public_Directory_ID}`);
	}, [data]);

	//if done loading return...
	return (
		<PageCards>
			{/* title */}
			<Typography variant='h3' textAlign='center' mb={2} color={'primary'}>
				Public Info Consent
			</Typography>

			{/* loading */}
			{isLoading && <LoadingSpinner />}

			{/* if not loading, display the following */}
			{!isLoading && (
				<Box sx={{ display: 'block', justifyContent: 'left' }}>
					{/* phone and email consents */}
					<Typography mt={2} textAlign={'left'} fontWeight={'bold'}>
						Select which items you would like to be displayed on the public info page.
					</Typography>
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={publicInfoConsent?.DisplayMobilePublically || false}
									disabled={isUploading}
									onChange={() => {
										handleConsent('DisplayMobilePublically');
									}}
									color='primary'
								/>
							}
							label='I consent to my mobile phone number being displayed.'
							labelPlacement='end'
						/>
						<FormControlLabel
							control={
								<Switch
									checked={publicInfoConsent?.DisplayEmailPublically || false}
									disabled={isUploading}
									onChange={() => {
										handleConsent('DisplayEmailPublically');
									}}
									color='primary'
								/>
							}
							label='I consent to my EPT email being displayed.'
							labelPlacement='end'
						/>
					</Stack>

					{/* include positions */}
					<Typography mt={2} textAlign={'left'} fontWeight={'bold'}>
						Select which Positions you hold that you want displayed on the public info page.
					</Typography>
					<Stack>
						{publicInfoPositions &&
							Object.keys(publicInfoPositions).length > 0 &&
							Object.keys(publicInfoPositions).map((key) => {
								return (
									<FormControlLabel
										key={key}
										control={
											<Switch
												checked={publicInfoPositions[key].DisplayPublically || false}
												onChange={() => {
													handlePositions(key);
												}}
												color='primary'
											/>
										}
										disabled={isUploading}
										label={key}
										labelPlacement='end'
									/>
								);
							})}
						{publicInfoPositions && Object.keys(publicInfoPositions).length === 0 && (
							<Typography textAlign={'left'}>No positions are currently available to display.</Typography>
						)}
					</Stack>
					<Typography textAlign='left' mt={3} fontWeight={'bold'}>
						You may view your public information{' '}
						<Link color={'#42a5f5'} href={link} target='_blank' rel='noopener'>
							here
						</Link>
						.
					</Typography>
				</Box>
			)}

			{isUploading && <LoadingSpinner />}

			{/* disclaimer */}
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Typography variant='caption' mt={3} textAlign='center'>
					*At a minimum, everyone will have their Google profile photo, name, EPT phone number, EPT fax number, EPT
					address and EPT website displayed publically.
				</Typography>
			</Box>
		</PageCards>
	);
}
