import React, { useContext, useState } from 'react';

import {
	GoogleAuthProvider,
	getRedirectResult,
	onAuthStateChanged,
	signInWithPopup,
	signInWithRedirect,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

import AuthContext from './authContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import AlertContext from '../ui/AlertContext';
import { auth } from '../../App';
import { Button } from '@mui/material';
import { SCOPES } from '../../scopes';

const provider = new GoogleAuthProvider();
for (const scope of SCOPES) provider.addScope(scope);
provider.setCustomParameters({
	hd: 'ept911.com',
});

export default function SignIn() {
	const authCtx = useContext(AuthContext);
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleGoogleSignIn = async () => {
		setIsLoading(true);

		await signInWithRedirect(auth, provider);

		// signInWithPopup(auth, provider)
		// 	.then(async (result) => {

		// 		if (result) {
		// 			verifyUserWithRetries(result.user);

		// 			// Get Google API Token
		// 			const credential = GoogleAuthProvider.credentialFromResult(result);
		// 			const gapiToken = credential.accessToken;
		// 			authCtx.updateGapiToken(gapiToken);
		// 		} else {
		// 			const user = auth.currentUser;
		// 			if (!user) {
		// 				setIsLoading(false);
		// 			}
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.error('error', error);
		// 	});
	};

	const verifyUserWithRetries = async (user, retryCount = 0, maxRetries = 3) => {
		try {
			const functions = getFunctions();
			const verifyEPTEmailWithCustomClaimsV2 = httpsCallable(functions, 'verifyEPTEmailWithCustomClaimsV2');

			const result = await verifyEPTEmailWithCustomClaimsV2({});
			const verified = result.data.verified;

			if (verified) {
				const expirationTime = user.stsTokenManager.expirationTime;

				authCtx.login(Number(expirationTime));
			} else {
				authCtx.logout();
				alertCtx.setSeverity('error');
				alertCtx.setMessage('An EPT Email account must be used to login. Retry logging in using an EPT Email account.');
				alertCtx.setActive(true);
				alertCtx.setTimer(10000);
			}
			setIsLoading(false);
		} catch (err) {
			if (retryCount < maxRetries) {
				setTimeout(() => {
					verifyUserWithRetries(user, retryCount + 1, maxRetries);
				}, Math.pow(2, retryCount) * 1000); // exponential backoff: 1s, 2s, 4s...
			} else {
				console.error('Max retries reached. Could not verify user:', err);
				setIsLoading(false);
				authCtx.logout();
				alertCtx.setSeverity('error');
				alertCtx.setMessage('Verification failed. Please try again later.');
				alertCtx.setActive(true);
				alertCtx.setTimer(10000);
			}
		}
	};

	// For Redirect Sign In
	onAuthStateChanged(auth, async (user) => {
		if (user) {
			setIsLoading(true);

			const result = await getRedirectResult(auth);

			if (result) {
				verifyUserWithRetries(result.user);

				// Get Google API Token
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const gapiToken = credential.accessToken;
				authCtx.updateGapiToken(gapiToken);
			} else {
				const user = auth.currentUser;
				if (!user) {
					setIsLoading(false);
				}
			}
		}
	});

	return (
		<>
			{isLoading && <LoadingSpinner />}
			{!isLoading && (
				<Button variant='contained' onClick={handleGoogleSignIn}>
					Log In
				</Button>
			)}
		</>
	);
}
