import React from 'react';
import { useState, useEffect } from 'react';

import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';

import {
	Box,
	Typography,
	TextField,
	Stack,
	FormControlLabel,
	Button,
	Radio,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Container,
	IconButton,
} from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';

import { v4 as uuidv4 } from 'uuid';

import AccordionUI from '../ui/AccordionUI';
import Tooltip from '../ui/Tooltip';
import AreObjectsEqual from '../localFunctions/AreObjectsEqual';
import { db } from '../../App';
import ConditionGroup from './ConditionGroup';

export default function Dashboard_DataInput(props) {
	const [expectedSheets, setExpectedSheets] = useState(JSON.parse(JSON.stringify(props.expectedSheets || null)));
	const [originalExpectedSheets, setOriginalExpectedSheets] = useState(
		JSON.parse(JSON.stringify(props.expectedSheets || null))
	);
	const [equal, setEqual] = useState(null);
	const [saving, setSaving] = useState(false);

	// useEffect(() => {
	// 	console.log(expectedSheets);
	// }, [expectedSheets]);

	/**
	 * checks if expectedSheets and originalExpectedSheets are equal
	 * if not equal, then allow save button to be active
	 */
	useEffect(() => {
		setEqual(AreObjectsEqual(expectedSheets, originalExpectedSheets));
	}, [expectedSheets, originalExpectedSheets]);

	/**
	 * updates a sheet's name
	 * @param {number} sheetIndex
	 * @param {*} newItem
	 */
	const updateSheetName = (sheetIndex, newItem) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].name = newItem.name;
			return newData;
		});
	};

	/**
	 * updates whether a field contains phi
	 * @param {number} sheetIndex
	 * @param {number} fieldIndex
	 * @param {boolean} checked
	 */
	const updateFieldPHI = (sheetIndex, fieldIndex, checked) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].expectedFields[fieldIndex].phi = checked;
			return newData;
		});
	};

	/**
	 * updates a fields phiKey (different that phi)
	 * @param {*} sheetIndex
	 * @param {*} fieldIndex
	 */
	const updatePHIKey = (sheetIndex, fieldIndex) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];

			newData[sheetIndex].expectedFields.map((field, index) => {
				if (fieldIndex === index) {
					field.phiKey = true;
				} else {
					field.phiKey = false;
				}
			});
			return newData;
		});
	};

	/**
	 * updates a field's name
	 * @param {number} sheetIndex
	 * @param {number} fieldIndex
	 * @param {*} newItem
	 */
	const updateFieldName = (sheetIndex, fieldIndex, newItem) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].expectedFields[fieldIndex].name = newItem.name;
			return newData;
		});
	};

	/**
	 * updates a field's type
	 * @param {number} sheetIndex
	 * @param {number} fieldIndex
	 * @param {*} newItem
	 */
	const updateFieldType = (sheetIndex, fieldIndex, newItem) => {
		setExpectedSheets((prevData) => {
			const newData = [...prevData];
			newData[sheetIndex].expectedFields[fieldIndex].type = newItem.type;
			return newData;
		});
	};

	/**
	 * adds a field to a sheet
	 * @param {number} sheetIndex
	 */
	const addField = (sheetIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			const expectedFields = [...newState[sheetIndex].expectedFields];
			const newField = { id: `${expectedFields.length}`, name: '', type: '' };
			expectedFields.push(newField);
			newState[sheetIndex].expectedFields = expectedFields;
			return newState;
		});
	};

	/**
	 * Adds a new sheet with default calculated columns for 'Report Date' and 'Sheet'.
	 */
	const addSheet = () => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			// Default rules that are not editable
			const defaultCalculatedColumns = [
				{
					ruleName: 'Report Date',
					conditions: {}, // You can define default conditions if needed
					calculation: {
						calculationType: 'REPORT_DATE',
						calculationColumn1: '',
						calculationColumn2: '',
						comparisonOperator: '',
					},
					editable: false,
					type: 'Date',
				},
				{
					ruleName: 'Sheet',
					conditions: {}, // You can define default conditions if needed
					calculation: {
						calculationType: 'SHEET',
						calculationColumn1: '',
						calculationColumn2: '',
						comparisonOperator: '',
					},
					editable: false,
					type: 'String',
				},
			];

			// Construct the new sheet
			const newSheet = {
				id: `${newState.length}`,
				name: '',
				expectedFields: [],
				calculatedColumns: defaultCalculatedColumns,
			};

			// Add the new sheet to the state
			newState.push(newSheet);
			return newState;
		});
	};

	/**
	 * updates a calculated column
	 * @param {number} sheetIndex
	 * @param {*} e
	 */
	const handleCalculationChange = (sheetIndex, calculatedColumnIndex, e, field) => {
		const value = e.target.value;
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			const calculatedColumn = newState[sheetIndex].calculatedColumns[calculatedColumnIndex];

			if (!calculatedColumn.calculation) {
				calculatedColumn.calculation = {};
			}

			if (field === 'ruleName') {
				calculatedColumn.ruleName = value;
			} else if (field === 'calculationType') {
				calculatedColumn.calculation.calculationType = value;

				// Auto-set the data type based on the calculation type
				let dataType;
				switch (value) {
					case 'ADD':
					case 'SUBTRACT':
					case 'MULTIPLY':
					case 'DIVIDE':
					case 'DATE_SUBTRACT_WITH_TIME':
					case 'DATE_SUBTRACT_WITHOUT_TIME':
					case 'SUBTRACT_DAYS':
						dataType = 'Number';
						break;
					case 'DATE_ADD':
					case 'ADD_DAYS':
					case 'REPORT_DATE':
						dataType = 'Date';
						break;
					case 'BOOLEAN_CONDITION':
						dataType = 'Boolean';
						break;
					case 'SHEET':
					case 'STRING':
						dataType = 'String';
						break;
					default:
						dataType = null; // or 'String' as a default
				}
				calculatedColumn.type = dataType;
			} else {
				calculatedColumn.calculation[field] = value;
			}
			return newState;
		});
	};

	const initializeConditions = (sheetIndex, calculatedColumnIndex) => {
		setExpectedSheets((prevState) => {
			// Create a shallow copy of the previous state
			const newState = [...prevState];

			// Navigate to the correct calculated column
			const calculatedColumn = newState[sheetIndex].calculatedColumns[calculatedColumnIndex];

			// Initialize conditions as a group node
			calculatedColumn.conditions = {
				type: 'group',
				operator: 'AND', // Default operator; you can allow user selection later
				children: [],
			};

			return newState;
		});
	};

	const handleOperatorChange = (sheetIndex, calculatedColumnIndex, nodeId, value) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			// Navigate to the correct calculated column
			const calculatedColumn = newState[sheetIndex].calculatedColumns[calculatedColumnIndex];

			// Function to update the node within the newState based on id
			const updateNodeById = (node) => {
				if (node.id === nodeId) {
					node.operator = value;
				} else if (node.type === 'group' && node.children) {
					node.children.forEach((child) => {
						updateNodeById(child);
					});
				}
			};

			updateNodeById(calculatedColumn.conditions);

			return newState;
		});
	};

	/**
	 * Adds a new condition to a specific calculatedColumn in a sheet.
	 * @param {number} sheetIndex - The index of the sheet where the calculatedColumn resides.
	 * @param {number} calculatedColumnIndex - The index of the calculatedColumn to which the condition should be added.
	 */
	const addCondition = (sheetIndex, calculatedColumnIndex, parentNodeId) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			// Navigate to the correct calculated column
			const calculatedColumn = newState[sheetIndex].calculatedColumns[calculatedColumnIndex];

			// Function to add the condition to the node with parentNodeId
			const addConditionToNode = (node) => {
				if (node.id === parentNodeId) {
					node.children.push({
						id: uuidv4(),
						type: 'condition',
						conditionName: null,
						conditionType: null,
						conditionValue: null,
					});
				} else if (node.type === 'group' && node.children) {
					node.children.forEach((child) => {
						addConditionToNode(child);
					});
				}
			};

			addConditionToNode(calculatedColumn.conditions);

			return newState;
		});
	};

	const addGroup = (sheetIndex, calculatedColumnIndex, parentNodeId) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			const calculatedColumn = newState[sheetIndex].calculatedColumns[calculatedColumnIndex];

			const addGroupToNode = (node) => {
				if (node.id === parentNodeId) {
					node.children.push({
						id: uuidv4(), // Unique ID for the new group
						type: 'group',
						operator: 'AND', // Default operator
						children: [],
					});
				} else if (node.type === 'group' && node.children) {
					node.children.forEach((child) => {
						addGroupToNode(child);
					});
				}
			};

			addGroupToNode(calculatedColumn.conditions);

			return newState;
		});
	};

	/**
	 * Deletes a condition from a rule in a sheet.
	 * @param {number} sheetIndex - Index of the sheet in the array.
	 * @param {number} ruleIndex - Index of the rule in the sheet's array.
	 * @param {number} conditionIndex - Index of the condition in the rule's conditions array.
	 */
	const deleteCondition = (sheetIndex, calculatedColumnIndex, nodeId) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			const calculatedColumn = newState[sheetIndex].calculatedColumns[calculatedColumnIndex];

			const deleteNodeById = (node, parent) => {
				if (node.id === nodeId && parent) {
					parent.children = parent.children.filter((child) => child.id !== nodeId);
				} else if (node.type === 'group' && node.children) {
					node.children.forEach((child) => {
						deleteNodeById(child, node);
					});
				}
			};

			// Start the recursive deletion from the root node
			deleteNodeById(calculatedColumn.conditions, null);

			return newState;
		});
	};

	/**
	 * Updates a specific property of a condition in a calculatedColumn of a sheet.
	 * @param {number} sheetIndex - The index of the sheet where the calculatedColumn resides.
	 * @param {number} calculatedColumnIndex - The index of the calculatedColumn containing the condition.
	 * @param {number} conditionIndex - The index of the condition to be updated.
	 * @param {string} field - The property of the condition to be updated (e.g., 'conditionName').
	 * @param {any} value - The new value to be set.
	 */
	const handleConditionChange = (sheetIndex, calculatedColumnIndex, nodeId, field, value) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			// Navigate to the correct calculated column
			const calculatedColumn = newState[sheetIndex].calculatedColumns[calculatedColumnIndex];

			// Recursive function to find and update the node by id
			const updateNodeById = (node) => {
				if (node.id === nodeId) {
					// Update the specific field
					node[field] = value;
				} else if (node.type === 'group' && node.children) {
					node.children.forEach((child) => {
						updateNodeById(child);
					});
				}
			};

			updateNodeById(calculatedColumn.conditions);

			return newState;
		});
	};

	/**
	 * Adds a new calculated column to a specific sheet.
	 * @param {number} sheetIndex - The index of the sheet to which the calculated column should be added.
	 */
	const addCalculatedColumn = (sheetIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];

			// Define a new calculated column with default values
			const newCalculatedColumn = {
				ruleName: null,
				// conditions: [
				// 	{
				// 		conditionName: null,
				// 		conditionType: null,
				// 		conditionValue: null,
				// 	},
				// ],
				calculation: {
					calculationType: null,
					calculationColumn1: null,
					calculationColumn2: null,
					comparisonOperator: null,
				},
				editable: true,
				type: null,
			};

			// Check if the sheet already has calculatedColumns and add the new one
			if (newState[sheetIndex].calculatedColumns) {
				newState[sheetIndex].calculatedColumns.push(newCalculatedColumn);
			} else {
				newState[sheetIndex].calculatedColumns = [newCalculatedColumn];
			}

			return newState;
		});
	};

	/**
	 * Deletes a calculated column (rule) from a sheet.
	 * @param {number} sheetIndex - Index of the sheet in the array.
	 * @param {number} ruleIndex - Index of the calculated column (rule) in the sheet's array.
	 */
	const deleteCalculatedColumn = (sheetIndex, ruleIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			// Remove the rule from the sheet's calculatedColumns array
			newState[sheetIndex].calculatedColumns.splice(ruleIndex, 1);
			return newState;
		});
	};

	/**
	 * move calculated column (rule) up
	 * @param {*} sheetIndex
	 * @param {*} calculatedColumnIndex
	 */
	const moveUp = (sheetIndex, calculatedColumnIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			const rules = newState[sheetIndex].calculatedColumns;

			// Check if current rule and the rule above it are both editable before swapping
			if (
				calculatedColumnIndex > 0 &&
				rules[calculatedColumnIndex].editable &&
				rules[calculatedColumnIndex - 1].editable
			) {
				const temp = rules[calculatedColumnIndex];
				rules[calculatedColumnIndex] = rules[calculatedColumnIndex - 1];
				rules[calculatedColumnIndex - 1] = temp;
			}
			return newState;
		});
	};

	/**
	 * move calculated column (rule) down
	 * @param {*} sheetIndex
	 * @param {*} calculatedColumnIndex
	 */
	const moveDown = (sheetIndex, calculatedColumnIndex) => {
		setExpectedSheets((prevState) => {
			const newState = [...prevState];
			const rules = newState[sheetIndex].calculatedColumns;

			// Check if current rule and the rule below it are both editable before swapping
			if (
				calculatedColumnIndex < rules.length - 1 &&
				rules[calculatedColumnIndex].editable &&
				rules[calculatedColumnIndex + 1].editable
			) {
				const temp = rules[calculatedColumnIndex];
				rules[calculatedColumnIndex] = rules[calculatedColumnIndex + 1];
				rules[calculatedColumnIndex + 1] = temp;
			}
			return newState;
		});
	};

	/**
	 * saves changes to dashboard.expectedSheets
	 */
	const saveChanges = async () => {
		setSaving(true);
		const dashboardRef = doc(db, 'dashboards', props.dashboardId);
		await updateDoc(dashboardRef, { expectedSheets: expectedSheets });
		setOriginalExpectedSheets(JSON.parse(JSON.stringify(expectedSheets)));
		setSaving(false);
	};

	return (
		<AccordionUI title='Data Inputs' subtitle='Open to adjust data inputs' parent={true}>
			{expectedSheets?.map((sheet, sheetIndex) => (
				<AccordionUI
					title={`Sheet - ${sheet.name}`}
					subtitle='Open to adjust sheet settings'
					parent={false}
					key={sheetIndex}
				>
					{/* Expected Sheet Name */}
					<>
						<Typography variant='subtitle2' align='center'>
							Expected Sheet Name
						</Typography>
						<TextField
							disabled={saving}
							label='Sheet Name'
							helperText='Enter the exact name of the sheet as it appears in the Excel file.'
							variant='outlined'
							size='small'
							margin='normal'
							value={sheet.name || ''}
							onChange={(e) =>
								updateSheetName(sheetIndex, {
									id: sheet.id,
									name: e.target.value,
								})
							}
							color='secondary'
							fullWidth
						/>
					</>

					{/* Expected Data */}
					<Stack spacing={1} mt={3}>
						{sheet?.expectedFields?.map((field, fieldIndex) => (
							<Grid container spacing={2} key={fieldIndex}>
								{/* phi key */}
								<Grid item sm={2}>
									{fieldIndex === 0 && (
										<Stack direction={'row'}>
											<Typography variant='subtitle2' align='center'>
												PHI Key Column
											</Typography>
											<Tooltip
												text='Select if this column is the key to identify who can see the PHI.  There can only be one PHI Key.'
												placement='right'
											/>
										</Stack>
									)}

									<Radio
										size='small'
										checked={field?.phiKey || false}
										onChange={(e) => {
											updatePHIKey(sheetIndex, fieldIndex);
										}}
										value={field?.phiKey || false}
										name={field?.name || ''}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								</Grid>

								{/* phi checkboxes */}
								<Grid item sm={2}>
									{fieldIndex === 0 && (
										<Stack direction={'row'}>
											<Typography variant='subtitle2' align='center'>
												Contains PHI
											</Typography>
											<Tooltip
												text='Select if this column contains Protected Health Information (PHI).  Do not enter or include any patient names or Social Security Numbers.'
												placement='right'
											/>
										</Stack>
									)}
									<FormControlLabel
										disabled={saving}
										control={
											<Checkbox
												size='small'
												checked={field?.phi || false}
												onChange={(e) => {
													updateFieldPHI(sheetIndex, fieldIndex, e.target.checked);
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
									/>
								</Grid>

								{/*expected fields */}
								<Grid item sm={4}>
									{fieldIndex === 0 && (
										<Typography variant='subtitle2' align='center'>
											Column Name in Sheet
										</Typography>
									)}
									<TextField
										disabled={saving}
										label='Column Name'
										variant='outlined'
										size='small'
										m={2}
										value={field?.name || ''}
										onChange={(e) =>
											updateFieldName(sheetIndex, fieldIndex, {
												id: sheet.id,
												name: e.target.value,
											})
										}
										fullWidth
									/>
								</Grid>

								{/* data types */}
								<Grid item sm={4}>
									{fieldIndex === 0 && (
										<Typography variant='subtitle2' align='center'>
											Data Types
										</Typography>
									)}

									<FormControl size='small' fullWidth>
										<InputLabel id='dataType-select-label'>Data Type</InputLabel>
										<Select
											labelId='dataType'
											id='dataType'
											value={field?.type || ''}
											label='Data Type'
											onChange={(e) =>
												updateFieldType(sheetIndex, fieldIndex, {
													id: sheet.id,
													type: e.target.value,
												})
											}
										>
											{props?.dataTypes?.map((type, i) => {
												return (
													<MenuItem value={type.name || ''} key={i}>
														{type.name || ''}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						))}
					</Stack>

					{/* Add new field */}
					<Box m={3} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip text='Adds a new field to the Expected Fields' placement='right'>
							<Button
								disabled={saving}
								variant='outlined'
								color='secondary'
								startIcon={<AddCircleOutlineRoundedIcon />}
								sx={{ width: '150px' }}
								onClick={() => {
									addField(sheetIndex);
								}}
							>
								NEW FIELD
							</Button>
						</Tooltip>
					</Box>

					<Typography fontWeight={'bold'} variant='h5' textAlign={'center'} mt={5} color={'primary'}>
						Calculated Columns
					</Typography>

					{/* Calculated Columns */}
					<Stack spacing={2} mt={1}>
						{sheet?.calculatedColumns?.map((calculatedColumn, calculatedColumnIndex) => (
							<Stack
								direction='row'
								key={calculatedColumnIndex}
								width='100%'
								p={2}
								sx={{
									backgroundColor: calculatedColumn.editable ? '#fcfcfc' : '#e0e0e0',
									borderRadius: '8px',
									border: '1px solid lightgrey',
								}}
							>
								{/* Move Rule Up */}
								<IconButton
									variant='outlined'
									color='primary'
									disabled={
										calculatedColumnIndex === 0 ||
										!calculatedColumn.editable ||
										!sheet.calculatedColumns[calculatedColumnIndex - 1]?.editable
									}
									onClick={() => moveUp(sheetIndex, calculatedColumnIndex)}
								>
									<ArrowUpwardIcon />
								</IconButton>

								{/* Move Rule Down */}
								<IconButton
									variant='outlined'
									color='primary'
									disabled={
										calculatedColumnIndex === sheet.calculatedColumns.length - 1 ||
										!calculatedColumn.editable ||
										!sheet.calculatedColumns[calculatedColumnIndex + 1]?.editable
									}
									onClick={() => moveDown(sheetIndex, calculatedColumnIndex)}
								>
									<ArrowDownwardIcon />
								</IconButton>

								<Box width={'100%'}>
									{/* Calculated Column Details */}
									<>
										<Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
											<Typography variant='body2' fontWeight={'bold'} textAlign={'center'}>
												{calculatedColumn.editable ? '' : 'Default '}Calculated Column
											</Typography>
											<Tooltip text='A virtual column that is calculated based on data. This virtual column is then saved to the database just like a real column. Settings can be adjusted below.' />
										</Stack>

										<Stack direction='row' spacing={1} width='100%' mb={1} mt={1}>
											{/* Calculated Column Name */}
											<TextField
												disabled={saving || !calculatedColumn.editable}
												label='Calculated Column Name'
												value={calculatedColumn?.ruleName || ''}
												onChange={(e) => handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'ruleName')}
												fullWidth
												margin='normal'
												variant='outlined'
												size='small'
											/>

											{/* Data Type */}
											<TextField
												label='Data Type'
												value={calculatedColumn?.type || ''}
												disabled
												fullWidth
												margin='normal'
												variant='outlined'
												size='small'
											/>
										</Stack>
									</>

									{/* Conditions (Optional) */}
									<Stack spacing={1} mb={5} mt={4}>
										<Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
											<Typography variant='body2' fontWeight={'bold'} textAlign={'center'}>
												Conditions (Optional)
											</Typography>
											<Tooltip text='Define conditions under which this calculation should be applied. Leaving the conditions blank will mean that this calculated column will be performed on every row of data.' />
										</Stack>

										{/* Render the condition tree */}
										{calculatedColumn.conditions && (
											<ConditionGroup
												conditionNode={calculatedColumn.conditions}
												sheetIndex={sheetIndex}
												calculatedColumnIndex={calculatedColumnIndex}
												handleConditionChange={handleConditionChange}
												deleteCondition={deleteCondition}
												addCondition={addCondition}
												addGroup={addGroup}
												handleOperatorChange={handleOperatorChange}
												saving={saving}
												calculatedColumn={calculatedColumn}
											/>
										)}

										{/* If conditions are not yet initialized, provide a button to add the first group */}
										{!calculatedColumn.conditions && (
											<Button
												variant='outlined'
												onClick={() => initializeConditions(sheetIndex, calculatedColumnIndex)}
												disabled={!calculatedColumn.editable}
											>
												Add Conditions
											</Button>
										)}
									</Stack>

									{/* Calculation */}
									<>
										<Typography variant='body2' fontWeight={'bold'} textAlign={'center'}>
											Calculated Column Calculation
										</Typography>

										<Stack direction='row' spacing={2} mt={1}>
											<TextField
												disabled={saving || !calculatedColumn.editable}
												label={`Column Name`}
												helperText={"Real or calculated column's name"}
												value={calculatedColumn?.calculation?.calculationColumn1 || ''}
												onChange={(e) =>
													handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'calculationColumn1')
												}
												fullWidth
												margin='normal'
												variant='outlined'
												size='small'
											/>

											<FormControl fullWidth variant='outlined' size='small' margin='normal'>
												<InputLabel>Operation Type</InputLabel>
												<Select
													disabled={saving || !calculatedColumn.editable}
													value={calculatedColumn?.calculation?.calculationType || ''}
													onChange={(e) =>
														handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'calculationType')
													}
													label='Operation Type'
												>
													<MenuItem value='ADD'>ADD</MenuItem>
													<MenuItem value='ADD_DAYS'>ADD DAYS</MenuItem>
													<MenuItem value='BOOLEAN_CONDITION'>BOOLEAN CONDITION</MenuItem>
													<MenuItem value='DATE_ADD'>DATE ADD</MenuItem>
													<MenuItem value='DATE_SUBTRACT_WITH_TIME'>DATE SUBTRACT WITH TIME</MenuItem>
													<MenuItem value='DATE_SUBTRACT_WITHOUT_TIME'>DATE SUBTRACT WITHOUT TIME</MenuItem>
													<MenuItem value='DIVIDE'>DIVIDE</MenuItem>
													<MenuItem value='MULTIPLY'>MULTIPLY</MenuItem>
													<MenuItem value='REPORT_DATE'>REPORT DATE</MenuItem>
													<MenuItem value='SHEET'>SHEET</MenuItem>
													<MenuItem value='STRING'>STRING</MenuItem>
													<MenuItem value='SUBTRACT'>SUBTRACT</MenuItem>
													<MenuItem value='SUBTRACT_DAYS'>SUBTRACT DAYS</MenuItem>
												</Select>
											</FormControl>

											{/* Comparison Operators (if BOOLEAN_CONDITION is chosen) */}
											{calculatedColumn?.calculation?.calculationType === 'BOOLEAN_CONDITION' && (
												<FormControl
													fullWidth
													variant='outlined'
													margin='normal'
													size='small'
													disabled={saving || !calculatedColumn.editable}
												>
													<InputLabel>Comparison Operator</InputLabel>
													<Select
														disabled={
															saving ||
															!calculatedColumn.editable ||
															calculatedColumn?.calculation?.calculationType !== 'BOOLEAN_CONDITION'
														}
														value={calculatedColumn?.calculation?.comparisonOperator || ''}
														onChange={(e) =>
															handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'comparisonOperator')
														}
														label='Comparison Operator'
													>
														<MenuItem value='>'>{'>'}</MenuItem>
														<MenuItem value='<'>{'<'}</MenuItem>
														<MenuItem value='>='>{'>='}</MenuItem>
														<MenuItem value='<='>{'<='}</MenuItem>
														<MenuItem value='==='>{'==='}</MenuItem>
														<MenuItem value='!=='>{'!=='}</MenuItem>
													</Select>
												</FormControl>
											)}

											<TextField
												disabled={saving || !calculatedColumn.editable}
												label={
													calculatedColumn?.calculation?.calculationType === 'BOOLEAN_CONDITION'
														? 'Value'
														: 'Column Name'
												}
												value={calculatedColumn?.calculation?.calculationColumn2 || ''}
												onChange={(e) =>
													handleCalculationChange(sheetIndex, calculatedColumnIndex, e, 'calculationColumn2')
												}
												fullWidth
												margin='normal'
												variant='outlined'
												size='small'
											/>
										</Stack>
									</>

									{/* Delete a Calculated Column */}
									<Stack alignItems='center' mt={2}>
										<Tooltip text='Delete a rule. Default rules cannot be deleted.' placement='right'>
											<Button
												variant='outlined'
												color='error'
												disabled={saving || !calculatedColumn.editable} // only enable deletion for editable rules
												onClick={() => {
													deleteCalculatedColumn(sheetIndex, calculatedColumnIndex);
												}}
												startIcon={<DeleteIcon />}
											>
												Delete Rule
											</Button>
										</Tooltip>
									</Stack>
								</Box>
							</Stack>
						))}
					</Stack>

					{/* Add new calculated column */}
					<Box mt={3} mb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip text='Adds a new Calculated Column' placement='right'>
							<Button
								disabled={saving}
								variant='outlined'
								color='secondary'
								startIcon={<AddCircleOutlineRoundedIcon />}
								// sx={{ width: '150px' }}
								onClick={() => {
									addCalculatedColumn(sheetIndex);
								}}
							>
								ADD CALCULATED COLUMN
							</Button>
						</Tooltip>
					</Box>

					{/* Save Button */}
					<Box mb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip text='Click to Save the Expected Sheets names.' placement='right'>
							<Button
								disabled={saving || equal}
								variant='contained'
								color='info'
								startIcon={<SaveRoundedIcon />}
								onClick={saveChanges}
							>
								SAVE DATA INPUT CHANGES
							</Button>
						</Tooltip>
					</Box>
				</AccordionUI>
			))}

			{/* Add a New Sheet */}
			<Box m={3} sx={{ display: 'flex', justifyContent: 'center' }}>
				<Tooltip text='Adds a new sheet to the Expected Sheets' placement='right'>
					<Button
						disabled={saving}
						variant='outlined'
						color='secondary'
						startIcon={<AddCircleOutlineRoundedIcon />}
						onClick={addSheet}
					>
						NEW SHEET
					</Button>
				</Tooltip>
			</Box>

			{/* Save Button */}
			<Box mb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
				<Tooltip text='Click to Save the Expected Sheets names.' placement='right'>
					<Button
						disabled={saving || equal}
						variant='contained'
						color='info'
						startIcon={<SaveRoundedIcon />}
						onClick={saveChanges}
					>
						SAVE DATA INPUT CHANGES
					</Button>
				</Tooltip>
			</Box>
		</AccordionUI>
	);
}
