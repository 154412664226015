import React, { useEffect, useState, useContext, Fragment } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
	Box,
	TextField,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Switch,
	FormControlLabel,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AlertContext from '../ui/AlertContext';
import CustomClaimsContext from '../auth/CustomClaimsContext';

import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';
import UserImage from '../ui/UserImage';

import formatPhoneNumber from '../localFunctions/formatPhoneNumber';
import { db } from '../../App';

const phoneRegExp = /^\(?([0-9]{3})\)?[-\s]?([0-9]{3})[-\s]?([0-9]{4})$/;
const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{3})+$/;

const formatAndValidatePhone = (value) => {
	const formattedValue = formatPhoneNumber(value);
	if (!phoneRegExp.test(formattedValue)) {
		return false;
	}
	return true;
};

const formatAndValidateEmail = (value) => {
	const formattedValue = value.trim();
	if (!emailRegExp.test(formattedValue)) {
		return false;
	}
	return true;
};

const validationSchema = Yup.object({
	Recovery_Email: Yup.string()
		.test('if-valid-email', 'Invalid Recovery Email address', formatAndValidateEmail)
		.required('Recovery Email is required'),
	Home_Address: Yup.string().required('Home Address is required'),
	Mobile_Phone: Yup.string()
		// .transform((value) => formatPhoneNumber(value))
		.test('is-valid-phone', 'Mobile Phone is not valid', formatAndValidatePhone)
		.required('Mobile Phone is required'),
	Home_Phone: Yup.string()
		.nullable()
		.test('is-valid-phone', 'Home Phone is not valid', function (value) {
			if (!value) return true; // If the value is empty or null, skip the validation
			return formatAndValidatePhone(value); // Run the custom validation for non-empty values
		}),
	Office_Phone: Yup.string()
		.nullable()
		.test('is-valid-phone', 'Office Phone is not valid', function (value) {
			if (!value) return true; // If the value is empty or null, skip the validation
			return formatAndValidatePhone(value); // Run the custom validation for non-empty values
		}),
	Fax: Yup.string()
		.nullable()
		.test('is-valid-phone', 'Fax is not valid', function (value) {
			if (!value) return true; // If the value is empty or null, skip the validation
			return formatAndValidatePhone(value); // Run the custom validation for non-empty values
		}),
});

export default function DirectoryForm({ isLoading, onClose, memberInfo }) {
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const { claims } = claimsCtx;
	const [loading, setLoading] = useState(null);
	const [saving, setSaving] = useState(false);
	const [data, setData] = useState(null);
	const [formData, setFormData] = useState(null);

	const formik = useFormik({
		initialValues: data || {},
		enableReinitialize: true,
		validationSchema: validationSchema,
	});

	//handles isLoading props
	useEffect(() => {
		setLoading(isLoading);
		if (!isLoading) {
			setData(null);
		}
	}, [isLoading]);

	//loads the memberInfo
	useEffect(() => {
		if (memberInfo && !isLoading) {
			setLoading(true);
			const getUserData = async () => {
				if (memberInfo && !loading) {
					// console.log(memberInfo);
					setData(memberInfo);
				}
			};

			getUserData();
			setLoading(false);
		}
	}, [memberInfo, loading]);

	//loads the Directory_Settings
	useEffect(() => {
		if (!formData) {
			const getDirectorySettings = async () => {
				const tempData = [];
				const querySnapshot = await getDocs(collection(db, 'Directory_Settings'));
				querySnapshot.forEach((doc) => {
					tempData.push({ ...doc.data(), ID: doc.id });
				});
				const sortedData = [...tempData].sort((a, b) => a.Order - b.Order);
				setFormData(sortedData);
				// console.log('formData', sortedData);
			};

			getDirectorySettings();
		}
	}, []);

	//handles form submission
	const handleSubmit = async () => {
		setSaving(true);

		const Data = {};
		formData.forEach((item) => {
			Data[item.Field_Key] = formik.values[item.Field_Key];
		});

		// Loop through all Data items and any item that is a string, remove any whitespace from the beginning and end
		Object.keys(Data).forEach((key) => {
			if (typeof Data[key] === 'string') {
				Data[key] = Data[key].trim();
			}
		});

		try {
			if (Object.keys(formData).length > 0) {
				const functions = getFunctions();
				const Directory_Update_User_Data = httpsCallable(functions, 'Directory_Update_User_Data');
				const response = await Directory_Update_User_Data({ Data: Data });
				const res = response.data;

				if (res.code === 200) {
					alertCtx.setActive(true);
					alertCtx.setSeverity('success');
					alertCtx.setMessage('Data was updated successfully.');
					alertCtx.setTimer(5000);
				} else {
					throw new Error('Failed to update data.');
				}
			}
		} catch (error) {
			console.error("Failed to update user's data:", error);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Failed to update data. Please try again later.');
		} finally {
			setSaving(false);
		}
	};

	//handles suspend button
	const handleSuspend = async () => {
		setSaving(true);

		const Data = {};
		formData.forEach((item) => {
			Data[item.Field_Key] = formik.values[item.Field_Key];
		});

		try {
			const functions = getFunctions();
			const Directory_Update_User_Data = httpsCallable(functions, 'Directory_Update_User_Status');
			const response = await Directory_Update_User_Data({ Data: Data, Status: 'Suspended' });
			const res = response.data;

			if (res.code === 200) {
				alertCtx.setActive(true);
				alertCtx.setSeverity('success');
				alertCtx.setMessage('User was suspended.');
				alertCtx.setTimer(5000);
				// formik.values.Status = 'Suspended';
				setData({ ...data, Status: 'Suspended' });
			} else {
				throw new Error('Failed to update data.');
			}
		} catch (error) {
			console.error('Failed to suspend the user:', error);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Failed to suspend the user. Please try again later.');
		} finally {
			setSaving(false);
		}
	};

	//handles unsuspend button
	const handleUnsuspend = async () => {
		setSaving(true);

		const Data = {};
		formData.forEach((item) => {
			Data[item.Field_Key] = formik.values[item.Field_Key];
		});

		try {
			const functions = getFunctions();
			const Directory_Update_User_Data = httpsCallable(functions, 'Directory_Update_User_Status');
			const response = await Directory_Update_User_Data({ Data: Data, Status: 'Active' });
			const res = response.data;

			if (res.code === 200) {
				alertCtx.setActive(true);
				alertCtx.setSeverity('success');
				alertCtx.setMessage('User was unsuspended.');
				alertCtx.setTimer(5000);
				setData({ ...data, Status: 'Active' });
			} else {
				throw new Error('Failed to update data.');
			}
		} catch (error) {
			console.error('Failed to unsuspend the user:', error);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Failed to unsuspend the user. Please try again later.');
		} finally {
			setSaving(false);
		}
	};

	//handles delete button
	const handleDelete = async () => {
		setSaving(true);

		const Data = {};
		formData.forEach((item) => {
			Data[item.Field_Key] = formik.values[item.Field_Key];
		});

		try {
			const functions = getFunctions();
			const Directory_Update_User_Data = httpsCallable(functions, 'Directory_Update_User_Status');
			const response = await Directory_Update_User_Data({ Data: Data, Status: 'Deleted' });
			const res = response.data;

			if (res.code === 200) {
				alertCtx.setActive(true);
				alertCtx.setSeverity('success');
				alertCtx.setMessage('User was deleted.');
				alertCtx.setTimer(5000);
				setData({ ...data, Status: 'Deleted' });
			} else {
				throw new Error('Failed to update data.');
			}
		} catch (error) {
			console.error('Failed to delete the user:', error);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Failed to delete the user. Please try again later.');
		} finally {
			setSaving(false);
		}
	};

	//handles undeleting a user
	const handleUndelete = async () => {
		setSaving(true);

		const Data = {};
		formData.forEach((item) => {
			Data[item.Field_Key] = formik.values[item.Field_Key];
		});

		try {
			const functions = getFunctions();
			const Directory_Update_User_Data = httpsCallable(functions, 'Directory_Update_User_Status');
			const response = await Directory_Update_User_Data({ Data: Data, Status: 'Suspended' });
			const res = response.data;

			if (res.code === 200) {
				alertCtx.setActive(true);
				alertCtx.setSeverity('success');
				alertCtx.setMessage('User was undeleted.');
				alertCtx.setTimer(5000);
				setData({ ...data, Status: 'Suspended' });
			} else {
				throw new Error('Failed to update data.');
			}
		} catch (error) {
			console.error('Failed to undeleted the user:', error);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Failed to undeleted the user. Please try again later.');
		} finally {
			setSaving(false);
		}
	};

	return (
		<>
			{/* Title */}
			<Typography variant='h3' textAlign='center' mb={2} color={'primary'}>
				Directory Info
			</Typography>

			{/* display if no data/user */}
			{!data && !loading && (
				<Typography variant='h5' textAlign='center' mb={2} color={'primary'}>
					Select a user from the <b>Members</b> list to view their information.
				</Typography>
			)}

			{/* closes the current user */}
			{!loading && data && (
				<Box display='flex' justifyContent='right'>
					<Tooltip text="Closes the current person's info.">
						<DisabledByDefaultRoundedIcon
							color='primary'
							onClick={() => {
								setData(null);
								onClose();
							}}
						/>
					</Tooltip>
				</Box>
			)}

			{/* loading spinner */}
			{loading && <LoadingSpinner />}

			{/* user name & avatar */}
			{!loading && data && (
				<Stack alignItems='center'>
					<UserImage photoURL={data.photoUrl} name={`${data.Preferred_Name} ${data.Last_Name}`} />
					<Typography variant='h4' color='primary' mb={2} textAlign={'center'}>
						{data.Preferred_Name} {data.Last_Name}
					</Typography>
				</Stack>
			)}

			{/* buttons at top */}
			{!loading && data && claimsCtx?.claims?.admin && (saving ? <LoadingSpinner /> : null)}
			{!loading && !saving && data && claimsCtx?.claims?.admin && (
				<Stack direction='row' spacing={2} justifyContent='center' mb={2} mt={3}>
					<Button
						variant='contained'
						startIcon={<SaveRoundedIcon />}
						color='saveButton'
						disabled={saving}
						onClick={handleSubmit}
					>
						Save
					</Button>

					{data.Status === 'Active' && (
						<Button
							variant='contained'
							startIcon={<RemoveCircleOutlineRoundedIcon />}
							color='otherButton'
							disabled={saving}
							onClick={handleSuspend}
						>
							Suspend
						</Button>
					)}

					{data.Status === 'Suspended' && (
						<Button
							variant='contained'
							startIcon={<RemoveCircleOutlineRoundedIcon />}
							color='otherButton'
							disabled={saving}
							onClick={handleUnsuspend}
						>
							Unsuspend
						</Button>
					)}

					{data.Status === 'Deleted' && (
						<Tooltip text='The user must be undeleted first.'>
							<div>
								<Button
									variant='contained'
									startIcon={<RemoveCircleOutlineRoundedIcon />}
									color='otherButton'
									disabled
									onClick={handleUnsuspend}
								>
									Unsuspend
								</Button>
							</div>
						</Tooltip>
					)}

					{data.Status === 'Active' && (
						<Tooltip text='The user must be suspended first.'>
							<div>
								<Button variant='contained' startIcon={<DeleteIcon />} color='deleteButton' disabled>
									Delete
								</Button>
							</div>
						</Tooltip>
					)}

					{data.Status === 'Suspended' && (
						<Button
							variant='contained'
							startIcon={<DeleteIcon />}
							color='deleteButton'
							disabled={saving}
							onClick={handleDelete}
						>
							Delete
						</Button>
					)}

					{data.Status === 'Deleted' && (
						<Button
							variant='contained'
							startIcon={<DeleteIcon />}
							color='deleteButton'
							disabled={saving}
							onClick={handleUndelete}
						>
							Undelete
						</Button>
					)}
				</Stack>
			)}

			{/* form */}
			{!loading && data && (
				<form>
					{formData &&
						formData.map((item, index) => {
							if (item.Active && (claims.admin || item.Public)) {
								// console.log(item);

								// STRING or PHONE items
								if (
									item.FieldType === undefined ||
									item.FieldType === null ||
									item.FieldType === 'STRING' ||
									item.FieldType === 'PHONE'
								) {
									return (
										<TextField
											key={index}
											disabled={!claims.admin || !item.Editable_By_Admin || saving}
											margin='dense'
											id={item.Field_Key}
											label={item.Label}
											fullWidth
											variant='outlined'
											color='secondary'
											value={formik.values[item.Field_Key] || ''}
											onChange={(e) => {
												const value = item.FieldType === 'PHONE' ? formatPhoneNumber(e.target.value) : e.target.value;
												formik.setFieldValue(item.Field_Key, value);
											}}
											onBlur={formik.handleBlur}
											type={'text'}
											InputLabelProps={{ shrink: data[item.Field_Key]?.length > 0 || true }}
											error={formik.touched[item.Field_Key] && Boolean(formik.errors[item.Field_Key])}
											helperText={formik.touched[item.Field_Key] && formik.errors[item.Field_Key]}
										/>
									);
								}

								// DATE Items
								else if (item.FieldType === 'DATE') {
									return (
										<LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
											<DatePicker
												id={item.Field_Key}
												disabled={!claims.admin || !item.Editable_By_Admin || saving}
												label={item.Label}
												value={formik.values[item.Field_Key] ? dayjs(formik.values[item.Field_Key]) : null}
												onChange={(e) => {
													if (e === null) formik.setFieldValue(item.Field_Key, '');
													else {
														const newDate = `${e.$y}-${('0' + (e.$M + 1)).slice(-2)}-${('0' + e.$D).slice(-2)}`;
														formik.setFieldValue(item.Field_Key, newDate);
													}
												}}
												onBlur={formik.handleBlur}
												sx={{ width: '100%', marginTop: '0.75rem', marginBottom: '0.5rem' }}
												components={{
													TextField: TextField,
												}}
												componentsProps={{
													textField: { label: item.Label },
												}}
											/>
										</LocalizationProvider>
									);
								}

								// BOOLEAN items
								else if (item.FieldType === 'BOOL') {
									return (
										<FormControlLabel
											key={index}
											control={
												<Switch
													id={item.Field_Key}
													disabled={!claims.admin || !item.Editable_By_Admin || saving}
													color='secondary'
													checked={
														formik.values[item.Field_Key] === '' ? false : formik.values[item.Field_Key] || false
													}
													onChange={(event) => {
														const e = formik.values[item.Field_Key] === '' ? true : !formik.values[item.Field_Key];
														formik.setFieldValue(item.Field_Key, e);
													}}
													onBlur={formik.handleBlur}
												/>
											}
											label={item.Label}
											labelPlacement='start'
										/>
									);
								}

								// SELECTION items
								else if (item.FieldType === 'SELECTION') {
									return (
										<FormControl
											fullWidth
											margin='dense'
											key={index}
											disabled={!claims.admin || !item.Editable_By_Admin || saving}
										>
											<InputLabel id='inputType'>{item.Label}</InputLabel>
											<Select
												color='secondary'
												fullWidth
												labelId={item.Label}
												label={item.Label}
												id={item.Field_Key}
												value={formik.values[item.Field_Key] || ''}
												onChange={(e) => {
													formik.setFieldValue(item.Field_Key, e.target.value);
												}}
												onBlur={formik.handleBlur}
												margin='dense'
											>
												{item.DropDownChoices &&
													item.DropDownChoices.sort().map((choice, choiceIndex) => {
														return (
															<MenuItem key={choiceIndex} value={choice}>
																{choice}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>
									);
								}
							}
						})}
				</form>
			)}

			{/* Positions */}
			{!loading &&
				data &&
				data.Positions &&
				data.Positions.map((position, index) => {
					return (
						<TextField
							key={index}
							disabled
							margin='dense'
							id={position}
							label='Position'
							fullWidth
							variant='outlined'
							color='secondary'
							value={position}
						/>
					);
				})}

			{/* buttons at bottom */}
			{!loading && data && claimsCtx?.claims?.admin && (saving ? <LoadingSpinner /> : null)}
			{!loading && !saving && data && claimsCtx?.claims?.admin && (
				<Stack direction='row' spacing={2} justifyContent='center' mb={2} mt={3}>
					<Button
						variant='contained'
						startIcon={<SaveRoundedIcon />}
						color='saveButton'
						disabled={saving}
						onClick={handleSubmit}
					>
						Save
					</Button>

					{data.Status === 'Active' && (
						<Button
							variant='contained'
							startIcon={<RemoveCircleOutlineRoundedIcon />}
							color='otherButton'
							disabled={saving}
							onClick={handleSuspend}
						>
							Suspend
						</Button>
					)}

					{data.Status === 'Suspended' && (
						<Button
							variant='contained'
							startIcon={<RemoveCircleOutlineRoundedIcon />}
							color='otherButton'
							disabled={saving}
							onClick={handleUnsuspend}
						>
							Unsuspend
						</Button>
					)}

					{data.Status === 'Deleted' && (
						<Tooltip text='The user must be undeleted first.'>
							<div>
								<Button
									variant='contained'
									startIcon={<RemoveCircleOutlineRoundedIcon />}
									color='otherButton'
									disabled
									onClick={handleUnsuspend}
								>
									Unsuspend
								</Button>
							</div>
						</Tooltip>
					)}

					{data.Status === 'Active' && (
						<Tooltip text='The user must be suspended first.'>
							<div>
								<Button variant='contained' startIcon={<DeleteIcon />} color='deleteButton' disabled>
									Delete
								</Button>
							</div>
						</Tooltip>
					)}

					{data.Status === 'Suspended' && (
						<Button
							variant='contained'
							startIcon={<DeleteIcon />}
							color='deleteButton'
							disabled={saving}
							onClick={handleDelete}
						>
							Delete
						</Button>
					)}

					{data.Status === 'Deleted' && (
						<Button
							variant='contained'
							startIcon={<DeleteIcon />}
							color='deleteButton'
							disabled={saving}
							onClick={handleUndelete}
						>
							Undelete
						</Button>
					)}
				</Stack>
			)}
		</>
	);
}
