import React from 'react';
import { Fragment, useState, useContext, useEffect } from 'react';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { collection, getDoc, getDocs, doc, updateDoc } from 'firebase/firestore';

import { Box, Stack, Typography, TextField, Card, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import PageCards from '../components/ui/PageCards';
import AlertContext from '../components/ui/AlertContext';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import AreObjectsEqual from '../components/localFunctions/AreObjectsEqual';
import { db } from '../App';
import Tooltip from '../components/ui/Tooltip';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';

export default function Spaces() {
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext)
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [spaces, setSpaces] = useState(null);
	const [originalSpaces, setOriginalSpaces] = useState(null);


	//get GoogleSpaces from Firestore
	useEffect(() => {
		if (db && spaces === null) {
			const getData = async () => {
				setLoading(true);

				const spacesData = [];
				const querySnapshot = await getDocs(collection(db, 'GoogleSpaces'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					spacesData.push({ id: document.id, ...data });
					// console.log(data);
				});

				spacesData.sort((a, b) => {
					return a.spaceName.localeCompare(b.spaceName);
				});

				setSpaces(spacesData);
				setOriginalSpaces(JSON.parse(JSON.stringify(spacesData)));

				setLoading(false);
			};

			getData();
		}
	}, [db]);

	/**
	 * changes space details
	 * @param {string} e - value of item being updated
	 * @param {string} index - the index of the space to be updated
	 * @param {string} item - the item to be updated (i.e. spaceName)
	 * @param {string} subIndex - a subIndex if one exists (i.e. subIndex for spaceManagers)
	 */
	const handleSpaceChange = (e, index, item, subIndex) => {
		setSpaces((prevData) => {
			const newData = [...prevData];
			if (subIndex !== null) {
				newData[index][item][subIndex] = e.target.value;
			} else newData[index][item] = e.target.value;

			return newData;
		});
	};

	/**
	 * removes manager or member
	 * @param {string} index - the index of the space to be updated
	 * @param {string} item - the item to be updated (i.e. spaceName)
	 * @param {string} subIndex - a subIndex if one exists (i.e. subIndex for spaceManagers)
	 */
	const handleManagerMemberRemoval = (index, item, subIndex) => {
		setSpaces((prevData) => {
			const newData = [...prevData];
			newData[index][item].splice([subIndex], 1);
			return newData;
		});
	};

	const handleSpaceSave = async (space, index) => {
		setSaving(true);
		const data = { ...space };
		delete data.id;

		const ref = doc(db, 'GoogleSpaces', space.id);
		await updateDoc(ref, { ...data });

		const docRef = doc(db, 'GoogleSpaces', space.id);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setSpaces((prevData) => {
				const newData = [...prevData];
				newData[index] = { id: docSnap.id, ...docSnap.data() };
				return newData;
			});
			setOriginalSpaces((prevData) => {
				const newData = [...prevData];
				newData[index] = { id: docSnap.id, ...docSnap.data() };
				return JSON.parse(JSON.stringify(newData));
			});
		}

		setSaving(false);
	};

	const addSpace = async () => {
		setLoading(true);

		const functions = getFunctions();
		const Google_Spaces_Add_Space = httpsCallable(functions, 'Google_Spaces_Add_Space');
		await Google_Spaces_Add_Space({}).then((result) => {
			if (result) {
				// console.log(result.data.response);
				alertCtx.setMessage(result.data.message);
				alertCtx.setSeverity(result.data.status.toLowerCase());
				alertCtx.setTimer(10000);
				alertCtx.setTitle(`Added 'New Space' - ${result.data.status}`);
				alertCtx.setActive(true);
			}
		});

		const spacesData = [];
		const querySnapshot = await getDocs(collection(db, 'GoogleSpaces'));
		querySnapshot.forEach((document) => {
			let data = document.data();
			spacesData.push({ id: document.id, ...data });
			// console.log(data);
		});

		spacesData.sort((a, b) => {
			return a.spaceName.localeCompare(b.spaceName);
		});

		setSpaces(spacesData);
		setOriginalSpaces(JSON.parse(JSON.stringify(spacesData)));

		setLoading(false);
	};

	//display if loading
	if (loading) {
		return (
			<PageCards>
				<Typography variant='h3' color={'primary'} textAlign='center'>
					Google Spaces
				</Typography>
				<br />

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The Google Spaces listed below are maintained by EPT. All space details and memberships are updated daily at
						1 AM EST.
					</Typography>
				</Box>

				<br />

				<LoadingSpinner />

				<br />
			</PageCards>
		);
	}

	return (
		<Fragment>
			<PageCards>
				<Typography variant='h3' color={'primary'} textAlign='center'>
					Google Spaces
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The Google Spaces listed below are maintained by EPT. All space details and memberships are updated daily at
						1 AM EST.
					</Typography>
				</Box>
				{claimsCtx?.claims?.admin && (
					<Box display={'flex'} justifyContent={'right'}>
						<Tooltip text='Add a new space.'>
							<Button variant='contained' color='info' endIcon={<AddCircleOutlineRoundedIcon />} onClick={addSpace}>
								Create a Space
							</Button>
						</Tooltip>
					</Box>
				)}
			</PageCards>

			{/* display spaces */}
			<Grid2 container spacing={2}>
				{spaces?.map((space, index) => {
					return (
						<Grid2 key={index} sm={12} md={6} lg={3}>
							<Card
								variant='outlined'
								sx={{ padding: '12px', margin: '8px', borderRadius: '16px', minHeight: '35rem' }}
							>
								<Stack direction='column' spacing={2}>
									<Typography fontWeight='bold' textAlign='center'>
										{originalSpaces[index].spaceName}
									</Typography>

									{/* Details */}
									<Stack>
										<Typography color='primary'>Details</Typography>
										<TextField
											disabled={!claimsCtx?.claims?.admin || saving}
											id='spaceName'
											label='Space Name'
											variant='outlined'
											margin='dense'
											value={space.spaceName}
											onChange={(e) => {
												handleSpaceChange(e, index, 'spaceName', null);
											}}
										/>

										<TextField
											disabled={!claimsCtx?.claims?.admin || saving}
											id='description'
											label='Description'
											variant='outlined'
											margin='dense'
											value={space.description || ''}
											onChange={(e) => {
												handleSpaceChange(e, index, 'description', null);
											}}
											inputProps={{
												maxLength: 150, // Set your desired character limit here
											}}
										/>

										<TextField
											disabled={!claimsCtx?.claims?.admin || saving}
											id='guidelines'
											label='Guidelines'
											variant='outlined'
											margin='dense'
											value={space.guidelines || ''}
											onChange={(e) => {
												handleSpaceChange(e, index, 'guidelines', null);
											}}
											inputProps={{
												maxLength: 5000, // Set your desired character limit here
											}}
										/>

										{claimsCtx?.claims?.admin && (
											<TextField
												disabled
												id='spaceID'
												label='Space ID'
												variant='outlined'
												margin='dense'
												value={space.spaceID}
											/>
										)}

										{claimsCtx?.claims?.admin && (
											<TextField
												disabled
												id='Firestore ID'
												label='Firestore ID'
												variant='outlined'
												margin='dense'
												value={space.id}
											/>
										)}
									</Stack>

									{/* Managers */}
									<Stack>
										<Typography color='primary' sx={{ justifyItems: 'center', display: 'flex' }}>
											Managers
											<Tooltip text='Can be a Google Group or individual email address.' />
										</Typography>
										{space?.spaceManagers?.map((manager, subIndex) => {
											if (subIndex < originalSpaces[index].spaceManagers.length)
												return (
													<Stack key={subIndex} direction='row'>
														<TextField
															disabled={!claimsCtx?.claims?.admin || saving}
															id='spaceManagers'
															label='Manager'
															variant='outlined'
															margin='dense'
															value={manager}
															onChange={(e) => {
																handleSpaceChange(e, index, 'spaceManagers', subIndex);
															}}
															sx={{ width: claimsCtx?.claims?.admin ? '85%' : '100%' }}
														/>
														{claimsCtx?.claims?.admin && (
															<Button
																disabled={!claimsCtx?.claims?.admin || saving}
																onClick={() => {
																	handleManagerMemberRemoval(index, 'spaceManagers', subIndex);
																}}
															>
																<Box sx={{ width: '15%' }}>
																	<DeleteIcon color='error' />
																</Box>
															</Button>
														)}
													</Stack>
												);
										})}
										{claimsCtx?.claims?.admin && (
											<TextField
												disabled={!claimsCtx?.claims?.admin || saving}
												id='spaceManagers'
												label='Manager'
												variant='outlined'
												margin='dense'
												value={space?.spaceManagers?.[originalSpaces[index]?.spaceManagers.length || 0] || ''}
												onChange={(e) => {
													handleSpaceChange(e, index, 'spaceManagers', originalSpaces[index].spaceManagers.length || 0);
												}}
												sx={{ width: '85%' }}
											/>
										)}
									</Stack>

									{/* Members */}
									<Stack>
										<Typography color='primary' sx={{ justifyItems: 'center', display: 'flex' }}>
											Members
											<Tooltip text='Can be a Google Group or individual email address.' />
										</Typography>
										{space?.spaceMembers?.map((member, subIndex) => {
											if (subIndex < originalSpaces[index].spaceMembers.length)
												return (
													<Stack key={subIndex} direction='row'>
														<TextField
															disabled={!claimsCtx?.claims?.admin || saving}
															id='spaceMembers'
															label='Member'
															variant='outlined'
															margin='dense'
															value={member}
															onChange={(e) => {
																handleSpaceChange(e, index, 'spaceMembers', subIndex);
															}}
															sx={{ width: claimsCtx?.claims?.admin ? '85%' : '100%' }}
														/>
														{claimsCtx?.claims?.admin && (
															<Button
																disabled={!claimsCtx?.claims?.admin || saving}
																onClick={() => {
																	handleManagerMemberRemoval(index, 'spaceMembers', subIndex);
																}}
															>
																<Box sx={{ maxWidth: '15%' }}>
																	<DeleteIcon color='error' />
																</Box>
															</Button>
														)}
													</Stack>
												);
										})}
										{claimsCtx?.claims?.admin && (
											<TextField
												disabled={!claimsCtx?.claims?.admin || saving}
												id='spaceMembers'
												label='Member'
												variant='outlined'
												margin='dense'
												value={space?.spaceMembers?.[originalSpaces[index]?.spaceMembers.length || 0] || ''}
												onChange={(e) => {
													handleSpaceChange(e, index, 'spaceMembers', originalSpaces[index].spaceMembers.length || 0);
												}}
												sx={{ maxWidth: '85%' }}
											/>
										)}
									</Stack>

									{/* Save Button */}
									{claimsCtx?.claims?.admin && (
										<Button
											variant='contained'
											startIcon={<SaveRoundedIcon />}
											onClick={() => {
												handleSpaceSave(spaces[index], index);
											}}
											color='saveButton'
											disabled={AreObjectsEqual(space, originalSpaces[index]) || saving}
										>
											SAVE
										</Button>
									)}
								</Stack>
							</Card>
						</Grid2>
					);
				})}
			</Grid2>
			<br />
		</Fragment>
	);
}
