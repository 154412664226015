import React, { Fragment, useEffect, useState } from 'react';
import { doc, getDoc, collection, getDocs, updateDoc, addDoc } from 'firebase/firestore';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Stack, Typography, TextField, IconButton, Button, Tabs, Tab } from '@mui/material';

import { db } from '../../App';
import PageCards from '../ui/PageCards';
import LoadingSpinner from '../ui/LoadingSpinner';
import DashboardSettings_UploadHandler from './DashboardSettings_UploadHandler';
import UpdateArrayItem from './UpdateArrayItem';
import AreArraysEqual from './AreArraysEqual';
import Tooltip from '../ui/Tooltip';
import Dashboard_SendEmail from './Dashboard_SendEmail';
import Dashboard_Charts from './Dashboard_Charts';
import Dashboard_DataInput from './Dashboard_DataInput';

const defaultDashboard = {
	dashboard: { active: false, charts: [], info: '', restricted: false, restrictedTo: [] },
	emailSettings: {
		bcc: '',
		cc: '',
		emailHTML: '',
		emailSubject: '',
		emailTitle: '',
		fromGroupEmail: '',
		fromGroupName: '',
		plainText: '',
		template_id: 'd-e737b47592874685adf84df535d2ea01',
		to: '',
	},
	expectedSheets: [],
	name: 'New Dashboard',
};

export default function DashboardSettings() {
	const [loading, setLoading] = useState(null);
	const [dashboards, setDashboards] = useState(null);
	const [originalDashboards, setOriginalDashboards] = useState(null);
	const [dataTypes, setDataTypes] = useState(null);
	const [equivalent, setEquivalent] = useState(null);
	const [saving, setSaving] = useState(false);
	const [dashboardNameIndex, setDashboardNameIndex] = useState({ name: null, index: null });
	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	//sets dashboards
	useEffect(() => {
		async function getData() {
			setLoading(true);
			const tempDashboards = [];
			const querySnapshot = await getDocs(collection(db, 'dashboards'));
			querySnapshot.forEach((doc) => {
				tempDashboards.push({
					...doc.data(),
					id: doc.id,
				});
			});
			const sortedDashboards = tempDashboards.sort(function (a, b) {
				return a.name.localeCompare(b.name);
			});
			// console.log(tempDashboards)
			setDashboards(JSON.parse(JSON.stringify(sortedDashboards)));
			setOriginalDashboards(JSON.parse(JSON.stringify(sortedDashboards)));
			// setLoading(false);
		}
		getData();
	}, []);

	//gets dataTypes
	useEffect(() => {
		async function getDataTypes() {
			const docRef = doc(db, 'settings', 'Data Types');
			const docSnap = await getDoc(docRef);
			const tempDataTypes = [];
			docSnap.data()['Data Types'].map((item, i) => tempDataTypes.push({ id: item.id, name: item.name }));
			setDataTypes(tempDataTypes);
		}
		getDataTypes();
	}, []);

	//sets loading and equivalency
	useEffect(() => {
		if (dashboards && originalDashboards && dataTypes) {
			setLoading(false);
			setEquivalent(AreArraysEqual(dashboards, originalDashboards));
		} else setLoading(true);
	}, [dashboards, originalDashboards, dataTypes]);

	const saveDashboardChanges = async (dashboard) => {
		setSaving(true);
		const dashboardRef = doc(db, 'dashboards', dashboard.id);
		await updateDoc(dashboardRef, { ...dashboard });
		refreshDashboard(dashboard.id);
	};

	/**
	 * updates the dashboard's name
	 * @param {*} e
	 */
	const handleDashboardNameIndexChange = (e) => {
		setDashboardNameIndex((prevData) => {
			const newData = { ...prevData };
			newData.name = e.target.value;
			return newData;
		});
	};

	/**
	 * saves the new dashboard name to the dashboard
	 * @param {*} dashboard
	 */
	const handleSaveDashboardName = (dashboard) => {
		setSaving(true);
		dashboard.name = dashboardNameIndex.name;
		saveDashboardChanges(dashboard);
		setDashboardNameIndex({ name: null, index: null });
	};

	/**
	 * adds a dashboard with the default settings
	 */
	const addDashboard = async () => {
		setSaving(true);

		const docRef = await addDoc(collection(db, 'dashboards'), { ...defaultDashboard });
		const dashboardRef = doc(db, 'dashboards', docRef.id);
		const dashboardSnap = await getDoc(dashboardRef);

		const tempDashboards = JSON.parse(JSON.stringify([...dashboards]));
		tempDashboards.unshift({ ...dashboardSnap.data(), id: dashboardSnap.id });

		setDashboards(JSON.parse(JSON.stringify(tempDashboards)));
		setOriginalDashboards(JSON.parse(JSON.stringify(tempDashboards)));

		setSaving(false);
	};

	/**
	 * refreshes the dashboard with the dashboard id provided
	 * @param {string} dashboardId
	 */
	const refreshDashboard = async (dashboardId) => {
		const dashboardRef = doc(db, 'dashboards', dashboardId);
		const dashboardSnap = await getDoc(dashboardRef);
		const newDashboard = {
			...dashboardSnap.data(),
			id: dashboardSnap.id,
		};
		setDashboards(JSON.parse(JSON.stringify(UpdateArrayItem(dashboards, newDashboard, 'id'))));
		setOriginalDashboards(JSON.parse(JSON.stringify(UpdateArrayItem(originalDashboards, newDashboard, 'id'))));
		setSaving(false);
	};

	return (
		<Fragment>
			{/* page title */}
			<PageCards>
				<Typography variant='h3' color='primary' textAlign={'center'} mb={1}>
					Dashboards' Settings
				</Typography>

				<Box display='flex' justifyContent='right' alignItems='center'>
					<Tooltip title='Add a new Dashboard'>
						<Button variant='outlined' color='primary' endIcon={<AddCircleOutlineRoundedIcon />} onClick={addDashboard}>
							Add Dashboard
						</Button>
					</Tooltip>
				</Box>

				{/* if loading */}
				{loading && <LoadingSpinner />}

				{/* tabs */}
				{!loading && dataTypes && dashboards && (
					<Box sx={{ color: 'primary' }} display={'flex'} justifyContent={'center'}>
						<Tabs
							textColor='inherit'
							indicatorColor='secondary'
							value={selectedTab}
							onChange={handleTabChange}
							variant='scrollable'
							scrollButtons='auto'
							allowScrollButtonsMobile
						>
							{dashboards.map((dash, index) => (
								<Tab
									key={index}
									label={dash.name}
									sx={{
										fontSize: '1rem',
										color: selectedTab === index ? 'inherit' : 'grey.500',
									}}
								/>
							))}
						</Tabs>
					</Box>
				)}

				{/* dashboards */}
				{!loading &&
					dataTypes &&
					dashboards &&
					dashboards?.map((dashboard, dashboardIndex) => {
						return (
							!dashboard?.disabled &&
							selectedTab === dashboardIndex && (
								<Box mb={8} mt={5} key={dashboard.id}>
									{/* dashboard title */}
									<Stack direction='row' alignItems='center' display='flex' justifyContent='center' m={3}>
										<Typography
											variant='h4'
											textAlign='center'
											mb={2}
											color='primary'
											alignItems='center'
											alignContent='center'
											marginTop='auto'
											marginBottom='auto'
										>
											{dashboard.name}
										</Typography>
										<Tooltip text={`Click the edit the dashboard's name`}>
											<IconButton
												onClick={() => {
													setDashboardNameIndex((prevData) => {
														const newData = { ...prevData };
														newData.index = dashboardIndex;
														newData.name = JSON.parse(JSON.stringify(dashboard.name));
														return newData;
													});
												}}
											>
												<EditRoundedIcon />
											</IconButton>
										</Tooltip>
									</Stack>

									{/* saves dashboard name change */}
									{dashboardNameIndex?.index === dashboardIndex && (
										<Box sx={{ display: 'flex', justifyContent: 'center' }} m={2}>
											<TextField
												disabled={saving}
												label='Dashboard Name'
												name='name'
												variant='outlined'
												size='large'
												m={2}
												value={dashboardNameIndex?.name || ''}
												onChange={handleDashboardNameIndexChange}
											/>
											<IconButton
												color='primary'
												onClick={() => {
													handleSaveDashboardName(dashboard);
												}}
											>
												<SaveRoundedIcon />
											</IconButton>
										</Box>
									)}

									{/* Upload File */}
									<DashboardSettings_UploadHandler
										dashboard={dashboard}
										dataTypes={dataTypes}
										disabled={!equivalent}
										refreshDashboard={refreshDashboard}
										loading={loading}
									/>

									{/*expectedSheets Details & Settings */}
									<Dashboard_DataInput
										expectedSheets={dashboard.expectedSheets}
										dashboardId={dashboard.id}
										dataTypes={dataTypes}
										dashboardIndex={dashboardIndex}
									/>

									{/* Dashboard - Define Dashboards */}
									<Dashboard_Charts
										dashboard={dashboard.dashboard}
										dashboardID={dashboard.id}
										expectedSheets={dashboard.expectedSheets}
										dashboardIndex={dashboardIndex}
									/>

									{/* Dashboard Emails */}
									<Dashboard_SendEmail
										dashboard={dashboard}
										saveDashboardChanges={saveDashboardChanges}
										equivalent={equivalent}
										dashboardIndex={dashboardIndex}
									/>
								</Box>
							)
						);
					})}
			</PageCards>
		</Fragment>
	);
}
