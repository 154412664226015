import React, { useMemo, useEffect, useState, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';

import { MaterialReactTable } from 'material-react-table';
import {
	Box,
	Typography,
	Stack,
	Select,
	MenuItem,
	FormControl,
	Autocomplete,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Checkbox,
} from '@mui/material';

import moment from 'moment';
import * as d3 from 'd3';

import Tooltip from '../ui/Tooltip';
import LoadingSpinner from '../ui/LoadingSpinner';
import Dashboard_Query from './Dashboard_Query';
import AlertContext from '../ui/AlertContext';
import { db } from '../../App';

export default function Chart_Table({ chart, dashboard, onRendered }) {
	const alertCtx = useContext(AlertContext);
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(null);
	const [groupedData, setGroupedData] = useState(null);
	const [reportDates, setReportDates] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedUserOptions, setSelectedUserOptions] = useState(null);
	const [showDismissalModal, setShowDismissalModal] = useState(false);
	const [dismissalReason, setDismissalReason] = useState('');
	const [selectedMap, setSelectedMap] = useState(new Map());

	// If the chart is an admin chart, get the selectedUser options from Firestore settings/Users_For_Dashboards
	useEffect(() => {
		if (chart.admin) {
			const getUsers = async () => {
				const usersRef = doc(db, 'settings', 'Users_For_Dashboards');
				const usersSnapshot = await getDoc(usersRef);
				const usersData = usersSnapshot.data().DecryptedList || [];
				setSelectedUserOptions(usersData);
			};
			getUsers();
		}
	}, [chart.admin]);

	// Reset the data when the chart or dashboard changes
	useEffect(() => {
		setData(null);
		setGroupedData(null);
		setReportDates(null);
		setSelectedDate(null);
		setSelectedUser(null);
		setSelectedUserOptions(null);
		setShowDismissalModal(false);
		setDismissalReason('');
		setSelectedMap(new Map());
	}, [chart.title, dashboard]);

	// Reset data points if selectedUser changes
	useEffect(() => {
		if (selectedUser) {
			setData(null);
			setGroupedData(null);
			setReportDates(null);
			setSelectedDate(null);
			awaitQuery();
		}
	}, [selectedUser]);

	// gets the data for the dashboard
	useEffect(() => {
		if (!chart.admin || (chart.admin && selectedUser)) awaitQuery();
	}, [chart.title, dashboard.name, selectedUser]);

	const awaitQuery = async (reloadingUserData = false) => {
		setLoading(true);
		setData(null);

		let response;
		if (chart.data === 'Data-PHI') {
			const functions = getFunctions();
			const Dashboard_PHI_Query = httpsCallable(functions, 'Dashboard_PHI_Query');
			const result = await Dashboard_PHI_Query({ dashboard: dashboard, chart: chart, email: selectedUser });
			response = result.data;

			if (result.data.error) {
				alertCtx.setMessage(result.message);
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Data Query');
				alertCtx.setActive(true);
				setLoading(false);
				return;
			}
		} else {
			response = await Dashboard_Query(dashboard, chart);
		}
		// console.log('response: ', response);

		/**
		 * if data is empty, then display alert
		 * also stop loading
		 */
		if (response.error) {
			if (response.error === 'Firebase Composite Index')
				alertCtx.setMessage(`${response.message} <a href='${response.url}' target='_blank'>Link</a>`);
			else alertCtx.setMessage(response.message);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Data Query');
			alertCtx.setActive(true);
			setLoading(false);
			return;
		} else if (!response.data) {
			console.log('No Data Returned');
			alertCtx.setMessage(response.message);
			alertCtx.setSeverity('warning');
			alertCtx.setTitle('Data Query');
			alertCtx.setActive(true);
			setLoading(false);
			return;
		} else if (response.data) {
			// console.log('data', response.data);
			setData(response.data);
			if (!reloadingUserData) {
				processDates(response.data);
			} else {
				groupData(response.data);
			}
		}
	};

	// processes the dates based on the table settings
	const processDates = (data) => {
		// console.log('processDates...');
		// console.log('data: ', data);

		//gets the dates for the menu
		const dateItems = [];
		data.forEach((item) => {
			dateItems.push({ date: item['Report Date'] });
		});

		const datesData = Array.from(
			d3.group(dateItems, (d) => d.date),
			([date]) => ({ date })
		);
		// console.log('datesData: ', datesData);

		if (data.length > 0) {
			//  display only the Most Recent date
			if (chart.displayDateRange === 'Most Recent') {
				// Convert the string dates to Date objects for comparison
				const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

				// Find the latest date
				const latestDate = new Date(Math.max.apply(null, dateObjects));

				// Set selectedDate to the latest date
				setSelectedDate(latestDate.toISOString());
			}

			// display All Available Dates
			if (chart.displayDateRange === 'All Available') {
				setReportDates(datesData);

				// Convert the string dates to Date objects for comparison
				const dateObjects = datesData.map((dateItem) => new Date(dateItem.date));

				// Find the latest date
				const latestDate = new Date(Math.max.apply(null, dateObjects));

				// Set selectedDate to the latest date
				setSelectedDate(latestDate.toISOString());
			}
		} else {
			setReportDates(null); // No dates to display
			setLoading(false); // Stop loading
		}
	};

	// groups data by
	const groupData = (data) => {
		if (selectedDate) {
			// Define an array to hold key functions
			const keyFunctions = [];

			// Conditionally add key functions based on chart settings
			if (chart.tableGroupDataByFirst) {
				keyFunctions.push((d) => d[chart.tableGroupDataByFirst]);
			}
			if (chart.tableGroupDataBySecond) {
				keyFunctions.push((d) => d[chart.tableGroupDataBySecond]);
			}

			// console.log('data', data);

			// Use d3.rollup with dynamic key functions
			const tempGroupedData =
				Array.from(
					d3.rollup(
						data,
						(D) =>
							// D.length,
							({
								length: D.length,
								items: D,
							}),
						...keyFunctions // Spread the array into arguments
					),
					([key, value]) => ({ key, value })
				) || [];
			// console.log('tempGroupedData:', tempGroupedData);

			/**
			 * counts how many times the item was over the threshold
			 */
			tempGroupedData.map((item) => {
				// console.log('item', item);
				if (item?.value) {
					// console.log('item.value', item.value); // Log value to see if it is iterable

					// Ensure item.value is defined
					Array.from(item.value, ([key, value]) => ({ key, value })).map((subItem) => {
						// console.log('subItem', subItem);
						if (subItem.value?.length > (chart?.tableThreshold || 0)) {
							item.count ? (item.count += 1) : (item.count = 1);
						}
					});
				}
			});

			// console.log('tempGroupedData:', tempGroupedData);

			//removes people with no reports for selectedDate
			const filteredData = tempGroupedData.filter((x) => {
				// Assuming `x.value` is a Map that has keys which are dates
				if (x?.value instanceof Map) {
					// Normalize selected date to an ISO string or just the date part
					const selectedDateKey = new Date(selectedDate).toISOString().split('T')[0]; // YYYY-MM-DD

					// Check if any of the keys in `value` match `selectedDateKey`
					return Array.from(x.value.keys()).some((key) => {
						const keyISO = new Date(key).toISOString().split('T')[0]; // Convert the key to comparable format
						return keyISO === selectedDateKey;
					});
				}
				return false;
			});

			let finalTransformedData, newTransformedData;
			if (chart.data === 'Data-PHI') {
				newTransformedData = filteredData.flatMap((entry) =>
					Array.from(entry.value.values()).flatMap((value) =>
						value.items.map((item) => ({
							key: entry.key,
							...item,
						}))
					)
				);

				// console.log('newTransformedData', newTransformedData);

				finalTransformedData = newTransformedData.filter((item) => {
					// Normalize both dates to the same format before comparison
					const reportDateISO = new Date(item['Report Date']).toISOString();
					const selectedDateISO = new Date(selectedDate).toISOString();

					return reportDateISO === selectedDateISO;
				});
			} else {
				finalTransformedData = filteredData;
			}

			// console.log('finalTransformedData', finalTransformedData);
			setGroupedData(finalTransformedData);
			setLoading(false);
		}
	};

	// Re-run groupData when selectedDate changes
	useEffect(() => {
		groupData(data);
	}, [selectedDate]);

	// sends feedback if the component is rendered, used for Dashboard_SendEmail component
	useEffect(() => {
		if (onRendered && loading === false) {
			onRendered(false); // Pass the loading state back
		}
	}, [loading]);

	const columnsWithSummary = useMemo(
		() => [
			{
				accessorKey: 'key',
				header: chart.tableGroupDataByFirst,
				id: chart.tableGroupDataByFirst,
			},
			{
				accessorFn: (row) => row.value ? row.value.get(new Date(selectedDate))?.length : null,
				header: chart.title,
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
				Cell: ({ cell }) => {
					return (
						<div
							style={{
								color: Number.isNaN(chart.tableThreshold)
									? 'black'
									: cell.getValue() > chart.tableThreshold
									? 'red'
									: 'black',
							}}
						>
							{cell.getValue()}
						</div>
					);
				},
			},
			{
				accessorFn: (row) => row.count,
				header: (
					<Stack direction='row' alignItems='center'>
						{chart.tableSummaryColumnTitle}
						{chart.tableSummaryColumnTooltip && chart.tableSummaryColumnTooltip.length > 0 && (
							<Tooltip text={chart.tableSummaryColumnTooltip} />
						)}
					</Stack>
				),
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
				},
				Cell: ({ cell }) => {
					return (
						<div
							style={{
								color: Number.isNaN(chart.tableSummaryColumnThreshold)
									? 'black'
									: cell.getValue() > chart.tableSummaryColumnThreshold
									? 'red'
									: 'black',
							}}
						>
							{cell.getValue()}
						</div>
					);
				},
			},
		],
		[selectedDate]
	);

	const columnsWithoutSummary = useMemo(() => {
		// Return an empty array if the selectedColumns is not an array
		if (!Array.isArray(chart.selectedColumns)) {
			return [];
		}

		// Create columns based on the selected columns
		const columns = chart.selectedColumns.map((column) => {
			return {
				accessorFn: (row) => {
					const value = row[column];
					return typeof value === 'boolean' ? (value ? 'True' : '') : value;
				},
				header: column,
				muiTableHeadCellProps: {
					align: 'center',
				},
				muiTableBodyCellProps: {
					align: 'center',
					sx: {
						whiteSpace: 'normal', // Allow text to wrap
						wordWrap: 'break-word', // Break long words
					},
				},
				id: column,
			};
		});

		// Add the checkbox column if the chart has an AdminDismissalColumn
		if (chart.AdminDismissalColumn) {
			columns.unshift({
				id: 'adminDismissalCheckbox',
				header: '',
				Cell: ({ row }) => (
					<Checkbox checked={selectedMap.has(row.original.id)} onChange={() => handleCheckboxChange(row.original)} />
				),
				muiTableHeadCellProps: { align: 'center' },
				muiTableBodyCellProps: { align: 'center' },
			});
		}

		return columns;
	}, [selectedDate, chart.selectedColumns, selectedMap, groupData]);

	// Handle checkbox change
	const handleCheckboxChange = (row) => {
		const id = row.id; // or any unique field
		const newSelectedMap = new Map(selectedMap); // store ID -> full row

		if (newSelectedMap.has(id)) {
			newSelectedMap.delete(id);
		} else {
			newSelectedMap.set(id, row);
		}

		setSelectedMap(newSelectedMap);
	};

	// Apply dismissal
	const applyDismissal = async () => {
		setLoading(true);

		const dismissedItems = Array.from(selectedMap.values()).map((item) => ({
			...item,
			reason: dismissalReason,
		}));

		// Call cloud function Dashboard_Dismissal with the dismissedItems
		const functions = getFunctions();
		const Dashboard_Dismissal = httpsCallable(functions, 'Dashboard_Dismissal');
		await Dashboard_Dismissal({ dismissedItems, chart, dashboard: dashboard.id })
			.then((result) => {
				console.log('Dismissal successful:', result);
				alertCtx.setMessage('Dismissal successful.');
				alertCtx.setSeverity('success');
				alertCtx.setTitle('Dismissal');
				alertCtx.setActive(true);
			})
			.catch((error) => {
				console.error('Dismissal failed:', error);
				alertCtx.setMessage('Dismissal failed.');
				alertCtx.setSeverity('error');
				alertCtx.setTitle('Dismissal');
				alertCtx.setActive(true);
			});

		// call awaitQuery to refresh the data, but keep the same selectedDate
		await awaitQuery(true);

		setShowDismissalModal(false);
		setSelectedMap(new Map());
		setDismissalReason('');
		setLoading(false);
	};

	return (
		<>
			<Stack spacing={2} display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} mt={2}>
				{/* display loading spinner */}
				{loading && <LoadingSpinner />}

				{/* display chart */}
				{!loading && (
					<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<Stack>
							<Typography variant='h6' align='center' mb={1}>
								{chart.title}
							</Typography>

							<Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
								{/* don't display if there are multiple dates (reportDate) */}
								{!reportDates && selectedDate && (
									<Typography variant='h7' align='center' mb={1}>
										{moment(selectedDate).format('MMMM D, YYYY')}
									</Typography>
								)}

								{/* display if the autocomplete drop down if this is an admin chart */}
								{chart.admin && (
									<FormControl sx={{ minWidth: '25%' }}>
										<Autocomplete
											id='select-user'
											options={
												selectedUserOptions ? [...selectedUserOptions].sort((a, b) => a.name.localeCompare(b.name)) : []
											}
											getOptionLabel={(option) => option.name || ''}
											value={selectedUserOptions?.find((option) => option.email === selectedUser) || null}
											onChange={(event, newValue) => setSelectedUser(newValue ? newValue.email : null)}
											renderOption={(props, option) => (
												<li {...props} key={option.email}>
													{option.name}
												</li>
											)}
											renderInput={(params) => <TextField {...params} label='Select User' key={params.email} />}
										/>
									</FormControl>
								)}

								{/* display if there are multiple dates (reportDate) */}
								{selectedDate && reportDates && (
									<Box sx={{ marginBottom: 2 }} display='flex' justifyContent='center'>
										<Select
											labelId='select-report-date-label'
											id='select-report-date'
											value={selectedDate}
											onChange={(e) => setSelectedDate(e.target.value)}
										>
											{reportDates.map((item, index) => (
												<MenuItem key={index} value={new Date(item.date).toISOString()}>
													{moment(item.date).format('MMMM D, YYYY')}
												</MenuItem>
											))}
										</Select>
									</Box>
								)}
							</Stack>

							{/* Display Table */}
							<MaterialReactTable
								muiTablePaperProps={{
									elevation: 0, //change the mui box shadow
									sx: {
										'& tr:nth-of-type(even)': {
											backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
										},
									},
								}}
								displayColumnDefOptions={{
									'mrt-row-actions': {
										muiTableHeadCellProps: {
											align: 'center',
										},
										size: 200,
									},
								}}
								columns={chart.tableSummaryColumn || false ? columnsWithSummary : columnsWithoutSummary}
								data={groupedData || []}
								enablePagination={false}
								initialState={{
									density: 'compact',
									expanded: true, //expand all groups by default
									sorting: [{ id: chart?.selectedColumns?.[0] || 'All Staff', asc: true }], //sort by group by default
								}}
								getRowId={(row) => row.id}
							/>

							{/* Display Dismissal Button */}
							{chart.AdminDismissalColumn && (
								<Button
									variant='contained'
									onClick={() => setShowDismissalModal(true)}
									sx={{ maxWidth: '200px', margin: 'auto' }}
									disabled={selectedMap.size === 0}
								>
									Dismiss Selected
								</Button>
							)}

							{/* Display Dismissal Modal */}
							<Dialog open={showDismissalModal} onClose={() => setShowDismissalModal(false)}>
								<DialogTitle>Dismissal Reason</DialogTitle>
								<DialogContent>
									{/* Display selected items */}
									{selectedMap.size > 0 && (
										<Box m={2}>
											<Typography variant='h6'>Dismissing Selected Items:</Typography>
											<ul>
												{Array.from(selectedMap.values()).map((item) => (
													<li key={item.id}>{item['Chart ID']}</li>
												))}
											</ul>
										</Box>
									)}

									{/* Display dismissal reason input */}
									<TextField
										label='Reason'
										multiline
										rows={4}
										fullWidth
										value={dismissalReason}
										onChange={(e) => setDismissalReason(e.target.value)}
									/>
								</DialogContent>
								<DialogActions>
									<Button onClick={applyDismissal}>Apply</Button>
								</DialogActions>
							</Dialog>
						</Stack>
					</Box>
				)}
			</Stack>
		</>
	);
}
